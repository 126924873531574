import Cookie from 'js-cookie';
import theme from '../components/theme';

/**
 * @typedef {Object} MyNewportConfig
 * @property {string} basePath
 * @property {string} theme
 * @property {boolean} isLoggedIn
 */
class MyNewportService {
    /**
     * Creates a new My Newport Client Instance.
     * @param {MyNewportConfig} MyNewportConfig The config object.
     */
    constructor(MyNewportConfig) {
        let themeConfig = theme.name;
        if (themeConfig === 'xmas') {
            themeConfig = 'christmas';
        }

        this.theme = themeConfig;
        this.isLoggedIn = MyNewportConfig.isLoggedIn;

        if (process.env.NODE_ENV !== 'production') {
            console.log('NewportService', this.isLoggedIn);
        }

        if (MyNewportConfig?.basePath?.endsWith('/')) {
            this.basePath = MyNewportConfig.basePath.slice(0, 1);
        } else {
            this.basePath = MyNewportConfig?.basePath;
        }
    }

    getToken() {
        if (Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN)) {
            return atob(Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN));
        } else {
            return false;
        }
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.getToken()}`
        };
    }

    getLocalDiscoveryKey() {
        return localStorage.getItem('apptus.session') ? JSON.parse(localStorage.getItem('apptus.session')).customerKey : false;
    }

    apiPath(path) {
        return `${this.basePath}/api/${path}`;
    }

    logout() {
        return this.post('auth/logout');
    }

    createLink(path) {
        const cleaned = path.split('/').filter((item) => item.length).join('/');
        return `${this.basePath}/${cleaned}`;
    }

    createLoginRedirectLink(redirect = null) {
        if (!redirect) {
            return `${this.basePath}/login`;
        }

        return `${this.basePath}/login?theme=${this.theme}&redirectTo=${redirect}`;
    }

    createSignupRedirectLink(redirect = null) {
        if (!redirect) {
            return `${this.basePath}/signup`;
        }

        return `${this.basePath}/signup?theme=${this.theme}&redirectTo=${redirect}`;
    }

    async get(path) {
        if (!this.isLoggedIn) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'GET',
            headers: this.getHeaders(),
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response };
        }

        return await response.json();
    }

    async delete(path) {
        if (!this.isLoggedIn) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'DELETE',
            headers: this.getHeaders()
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response };
        }

        return response;
    }

    async post(path, data) {
        if (!this.isLoggedIn) {
            return { status: 'not-logged-in', data: null };
        }

        let response = await fetch(this.apiPath(path), {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify(data),
        });

        if (response.status >= 400) {
            console.error(response);
            return { status: 'error', data: null, response };
        }

        return await response.json();
    }

    async getWishlists() {
        try {
            let responseJson = await this.get('wishlist');

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWeddingList() {
        try {
            let responseJson = await this.get('wishlist/wedding-list');

            if (!responseJson.data) {
                return { status: 'error', data: {} };
            }

            return { status: 'success', data: responseJson.data };
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlistProducts(wishlistId) {
        try {
            let responseJson = await this.get(`wishlist/${wishlistId}`);

            if (responseJson.status === 'error') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async getWishlistProductIds() {
        try {
            let responseJson = await this.get('wishlist/product-ids');

            if (responseJson.status === 'error') {
                return [];
            }

            return responseJson?.ids || [];
        } catch (e) {
            return this.handleError(e);
        }
    }

    async addWishlistProduct(wishlistId, productId) {
        try {
            let responseJson = await this.post(`wishlist/${wishlistId}/item`, {
                product_id: productId,
                qty: 1
            });

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async removeWishlistProduct(wishlistId, productId) {
        try {
            let responseJson = await this.delete(`wishlist/${wishlistId}/item/${productId}`);

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async createWishlist({ name, type, text, location, date }) {
        const payload = {
            name,
            type,
            ...(text && { text }),
            ...(location && { location }),
            ...(date && { date }),
        };

        try {
            let responseJson = await this.post('wishlist', payload);

            if (responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            return this.handleError(e);
        }
    }

    async updateDiscoveryKey(discoveryKey) {
        try {
            let response = await this.post('account/discovery-key', {
                discovery_key: discoveryKey,
            });

            if (response.status === 'error') {
                console.error(`Couldn't update discovery key. [${response.status}]`);
            }
        } catch (e) {
            console.error('Couldn\'t update discovery key');
        }
    }

    handleError(e) {
        if (e) {
            console.error('[Wishlist]', e);
        } else {
            throw new Error('UnknownWishlistError');
        }

        return {
            status: 'critical',
            data: null,
            message: 'Failed fetching data',
        };
    }
}

export default MyNewportService;
