class StorefrontApiService {
    constructor() {
        this.basePath = '';
    }

    getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };
    }

    apiPath(path) {
        return `${this.basePath}/api/${path}`;
    }

    async get(path) {
        let response = await fetch(this.apiPath(path), {
            method: 'GET',
            headers: this.getHeaders(),
            credentials: 'include',
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return await response.json();
    }

    async delete(path) {
        let response = await fetch(this.apiPath(path), {
            method: 'DELETE',
            headers: this.getHeaders(),
            credentials: 'include',
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return response;
    }

    async post(path, data) {
        let response = await fetch(this.apiPath(path), {
            method: 'POST',
            headers: this.getHeaders(),
            credentials: 'include',
            body: JSON.stringify(data),
        });

        if (response.status >= 400) {
            return { status: 'error', data: null, response: response };
        }

        return await response.json();
    }

    async getProductByIds(productIds) {
        return this.get(`product/get/by-ids?ids=${productIds.join(',')}`);
    }

    async fetchMarketingLabels() {
        return this.get('properties/1797ebb9d09a5380bb104f407b52fe04');
    }

    /**
     * @param productIds
     * @returns {Promise<{cart: Object|null}|any>}
     */
    async addItemsToCart(productIds) {
        return this.post('cart/add-items', {
           productIds
        });
    }

    handleError(e) {
        if (e) {
            console.error('[StorefrontApiService]', e);
        } else {
            throw new Error('StorefrontApiServiceError');
        }

        return {
            status: 'critical',
            data: null,
            message: 'Failed fetching data',
        };
    }
}

export default StorefrontApiService;
