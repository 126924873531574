import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import theme from '../../components/theme';
import MyNewportService from '../MyNewportService';
import Cookies from 'js-cookie';

const useUserStore = create(
    persist(
        (set, get) => ({
            isLoggedIn: false,
            config: typeof window !== 'undefined' ? window?.__NEXT_DATA__?.props?.publicConfig : null,
            user: { // reset on logout
                name: null,
            },
            favorites: [],
            theme: theme.name,
            favoriteProducts: [],
            wishlists: [], // reset on logout
            weddingList: null, // reset on logout
            wishlistProducts: [], // reset on logout
            weddingMethods: {
                async toggleWeddingList(productId) {
                    const state = get();
                    const wishlistId = state.weddingList.id;

                    if (state.weddingMethods.existsInWeddingList(productId)) {
                        await state.wishlistMethods.removeFromWishlist(productId, wishlistId);
                    } else {
                        await state.wishlistMethods.addToWishlist(productId, wishlistId);
                    }

                    state.init();
                },
                hasWeddingList() {
                    return !!get().weddingList;
                },
                existsInWeddingList(productId) {
                    const items = get().weddingList?.items;

                    if (!items?.length) {
                        return false;
                    }

                    return !!items?.find((item) => item.product_id?.replaceAll('-', '') === productId);
                },
            },
            wishlistMethods: {
                async createNewWishlist({ name, type = 'standard', text, location, date }) {
                    let response = await get().getService().createWishlist({
                        name,
                        type,
                        text,
                        location,
                        date,
                    });

                    if (response.status === 'success') {
                        let newWishlists = [ ...get().wishlists ];
                        newWishlists.push(response.data);
                        set({ wishlists: newWishlists });
                    }

                    return response;
                },
                existsInWishlist(productId, wishlistId) {
                    const wishlistProducts = get().wishlistProducts;
                    return wishlistProducts.filter((item) => item.product_id === productId && item.wishlist_id === wishlistId).length > 0;
                },
                async toggleWishlist(productId, wishlistId) {
                    const wishlistMethods = get().wishlistMethods;
                    if (wishlistMethods.existsInWishlist(productId, wishlistId)) {
                        await wishlistMethods.removeFromWishlist(productId, wishlistId || get().wishlists[0].id);
                    } else {
                        await wishlistMethods.addToWishlist(productId, wishlistId || get().wishlists[0].id);
                    }
                    get().init();
                },
                async addToWishlist(productId, wishlistId) {
                    const service = get().getService();
                    const updatedWishlist = await service.addWishlistProduct(wishlistId, productId);

                    if (updatedWishlist.status === 'success') {
                        const newWishlists = get().wishlists.map((wishlist) => {
                            if (updatedWishlist.data.id === wishlist.id) {
                                wishlist = updatedWishlist.data;
                            }

                            return wishlist;
                        });

                        set({ wishlists: newWishlists });
                    }
                },
                async removeFromWishlist(productId, wishlistId) {
                    const service = get().getService();
                    const updatedWishlist = await service.removeWishlistProduct(wishlistId, productId);

                    if (updatedWishlist.status === 'success') {
                        const newWishlists = get().wishlists.map((wishlist) => {
                            if (updatedWishlist.data.id === wishlist.id) {
                                wishlist = updatedWishlist.data;
                            }

                            return wishlist;
                        });

                        set({ wishlists: newWishlists });
                    }
                },
            },
            favoriteMethods: {
                /**
                 * @param {Array<string>} productIds
                 * @param {string} wishlistId
                 */
                async moveToList(productIds = [], wishlistId) {
                    let counter = 0;

                    try {
                        await Promise.all(productIds?.forEach(async (item) => {
                            counter++;
                            return await get().wishlistMethods.addToWishlist(item, wishlistId);
                        }));

                        if (productIds?.length === counter) {
                            console.log('productIds', counter);
                        }

                        return true;
                    } catch (e) {
                        console.error(e);
                        return false;
                    }
                },
                addToFavorites(productId) {
                    const state = get();
                    const favorites = new Set(state.favorites);
                    favorites.add(productId);
                    set({ favorites: [ ...favorites ] });
                },
                removeFromFavorites(productId) {
                    const state = get();
                    const favorites = state.favorites.filter((item) => {
                        return item !== productId;
                    });
                    set({ favorites });
                },
                clearFavorites() {
                    set((state) => {
                        return { favorites: [] };
                    });
                },
                existsInFavorites(productId) {
                    const favorites = get().favorites;
                    return favorites.filter((item) => item === productId).length > 0;
                },
            },
            setConfig(config) {
                set({ config });
            },
            isHighlighted(productId) {
                if (get().favorites.includes(productId)) {
                    return true;
                }

                if (get().weddingList && get().weddingMethods.existsInWeddingList(productId)) {
                    return true;
                }

                return get().wishlistProducts.filter((item) => item.product_id === productId).length > 0;
            },
            async init() {
                const state = get();
                const service = state.getService();
                const wishlists = await service.getWishlists();
                const weddingList = await service.getWeddingList();
                const products = await service.getWishlistProductIds();
                const isLoggedIn = state.checkLoginStatus();

                set({ isLoggedIn });

                if (!isLoggedIn) {
                    return;
                }

                if (wishlists.status === 'success') {
                    set({ wishlists: wishlists.data });
                }

                if (weddingList.status === 'success') {
                    set({ weddingList: weddingList.data });
                }

                if (products?.length) {
                    set({ wishlistProducts: products });
                }
            },
            async logout() {
                const response = await get().getService().logout();
                if (response.status === 'success') {
                    Cookies.remove(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN);
                    Cookies.remove(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN, {
                        domain: typeof location === 'object' ? location.host.substring(location.host.indexOf('.')) : null,
                    });
                    set({
                        isLoggedIn: false,
                        user: {
                            name: null,
                        },
                        wishlists: [],
                        wishlistProducts: [],
                        weddingList: null,
                    });
                }
            },
            checkLoginStatus() {
                const isLoggedIn = !!Cookies.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN);

                if (get().isLoggedIn !== isLoggedIn) {
                    set({ isLoggedIn: isLoggedIn });
                }

                return isLoggedIn;
            },
            getService() {
                const state = get();

                if (state.config.myNewport) {
                    return new MyNewportService({
                        basePath: state.config.myNewport,
                        theme: state.theme,
                        isLoggedIn: state.isLoggedIn,
                    });
                }

                return new MyNewportService();
            },
            async addFavorite(productId, wishlistId = null) {
                const isLoggedIn = get().isLoggedIn;
                if (!isLoggedIn) {
                    get().favoriteMethods.addToFavorites(productId);
                } else {
                    const single = get().wishlists?.length === 1;
                    if (!single && !wishlistId) {
                        window.dispatchEvent(new CustomEvent('toggle-wishlist-product', { detail: { productId: productId } }));
                    } else {
                        await get().wishlistMethods.toggleWishlist(productId, wishlistId);
                    }
                }
            },
            async removeFavorite(productId, wishlistId = null) {
                const isLoggedIn = get().isLoggedIn;
                if (!isLoggedIn) {
                    get().favoriteMethods.removeFromFavorites(productId);
                } else {
                    const single = get().wishlists?.length === 1;
                    if (!single && !wishlistId) {
                        window.dispatchEvent(new CustomEvent('toggle-wishlist-product', { detail: { productId: productId } }));
                    } else {
                        await get().wishlistMethods.toggleWishlist(productId, wishlistId);
                    }
                }
            },
            async updateAccount() {
                const discoveryKey = get().getService().getLocalDiscoveryKey();
                if (discoveryKey) {
                    await get().getService().updateDiscoveryKey(discoveryKey);
                }
            },
        }),
        {
            name: 'user-storage',
            partialize: (state) => ({
                weddingList: state.weddingList,
                favorites: state.favorites,
                wishlistProducts: state.wishlistProducts,
            }),
        },
    ),
);

export default useUserStore;
